header {
    background-color: var(--darkPurple);
    color: var(--gold);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: var(--smallPadding) var(--mediumPadding);
}

.nav_links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.nav_link {
    padding: 0 var(--smallPadding);
    text-decoration: none;
    color: var(--gold);
}

.nav_link:hover, .header_Title :hover {
    cursor: pointer;
    color: var(--cream);
    font-size: 65%;

}

.header_Title {
    text-decoration: none;
    color: var(--gold);
}

.header_menuIcon {
    height: var(--big);
    display: none;
}


/* REACTIVENESS */
@media screen and (max-width: 670px) {
    .nav_links {
        display: none;
    }

    .header_menuIcon {
        display: flex;
    }
}