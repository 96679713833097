footer {
    position: absolute;
    bottom: 0;
    background-color: var(--darkPurple);
    height: 3rem;
    width: 100%;
}

.footer_copyright {
    color: var(--gold);

    text-align: center;
    padding: var(--smallPadding) var(--mediumPadding);

}