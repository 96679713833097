.hero {
    color: var(--gold);
    background-color: black;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
    box-sizing: border-box !important;

    height: 100vh;
}

.hero_logo {
    width: 25%;
    padding: var(--mediumPadding);
    position: relative;
}

.hero_subtitle {
    position: relative;
}

.hero_intro {
    text-align: center;
    line-height: 1.2;

    color: var(--mediumPurple);
    font-weight: 700;
    
    width: 45%;
    padding: var(--smallPadding);
    position: relative;
}

.hero_niwiMotto {
    display: inline;
    position: relative;
    color: var(--gold);
    font-style: italic;
    font-weight: normal;
}

.hero_continue {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    margin-bottom: 5rem;
    height: 100%;

}

.hero_learnMore {
    padding-top: var(--mediumPadding);
}

.hero_downArrow {
    padding-top: var(--smallPadding);
}


/* Video */
.videoContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.3;
    }

.videoContainer__video {
    /* optional */
    width: 100%;
    height: 100vh;
}


/* REACTIVENESS */
@media screen and (max-width: 670px) {
    .videoContainer__video {
        display: none;
    }

    .hero_intro {
        width: 90%;
    }

    .hero_subtitle {
        text-align: center;
        font-size: var(--big);
    }
}