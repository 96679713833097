.squadronMenu {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 40%;

    margin: var(--hugePadding) auto;
}

.squadronMenu:hover {
    cursor: pointer;
} 

.icon_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    padding: 0 var(--mediumPadding);

}

.icon_label {
    color: var(--gold);
    text-align: center;
    padding: var(--smallPadding) 0;
    justify-content: center;

    transition-property: font-size;
    transition-duration: 0.3s;
}

.icon_label:hover {
    font-size: 0.6rem;
}

/* REACTIVENESS */
@media screen and (max-width: 670px) {
    .icon_item {
        padding: 0 0.5rem;
    }

    .icon_label {
        display: none;
    }
}

