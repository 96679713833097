.categories_container {
    background-color: var(--darkPurple);
    margin: var(--largePadding) auto;
    padding: var(--mediumPadding);
    width: 40%;
    border-radius: 20px;
}

.categories_title {
    color: var(--gold);
    padding: 0 0 var(--smallPadding);
}

.categories_buttons {
    display: flex;
    justify-content: space-around;
}

.categories_button {
    border: none;
    border-radius: 10px;

    background-color: var(--lightPurple);
    color: var(--cream);

    padding: 0.5rem var(--smallPadding);
    width: 12rem;
}

.categories_button:hover {
    cursor: pointer;
}

/* REACTIVENESS */
@media screen and (max-width: 1000px) {
    .categories_title {
        font-size: var(--big);
    }

    .categories_button {
        width: 10rem;
    }

    .categories_container {
        width: 60%;
    }
}

@media screen and (max-width: 550px) {
    .categories_title {
        font-size: 1.3rem;
    }

    .categories_button {
        width: 6rem;
    }

    .categories_container {
        width: 60%;
    }
}