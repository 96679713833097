/* Import fonts */
@import url('https://fonts.googleapis.com/css2?family=Arvo&display=swap'); /* Arvo */
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap'); /* Raleway */

/* Aurabesh fonts */
@font-face {
    font-family: 'Aurebesh';
    src: url("./aurebesh/Aurebesh.otf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Aurebesh';
    src: url("./aurebesh/Aurebesh\ Italic.otf");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Aurebesh';
    src: url("./aurebesh/Aurebesh\ Bold.otf");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Aurebesh';
    src: url("./aurebesh/Aurebesh\ Bold\ Italic.otf");
    font-weight: bold;
    font-style: italic;
}

h1, h2, h3 {
    font-family: 'Arvo', serif;
}

h1:hover, h2:hover, h3:hover, p:hover, a:hover, li:hover, button:hover {
    font-family: 'Aurebesh', serif;
}



h1, p, a, li, button {
    font-size: var(--normal);

}

h2 {
    font-size: var(--biggest);
}

h3 {
    font-size: var(--big);
}


p, a, li, button {
    font-family: 'Raleway', sans-serif;
}