


/* Base */
@import "./styling/reset.css";
@import "./styling/settings.css";
@import "./styling/typography.css";

/* common */
@import "./styling/header.css";
@import "./styling/footer.css";

/* home */
@import "./styling/hero.css";
@import "./styling/homeMenu.css";

/* Squadrons */
@import "./styling/squadInfo.css";
@import "./styling/squadronsMenu.css";

/* Videos */
@import "./styling/categories.css";
@import "./styling/media.css";

/* Join Us */
@import "./styling/join.css";

/* Menu burger */
@import "./styling/menu.css";


body {
    background-image: url("./assets/backgroundImage.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;

    position:absolute;
    width: 100%;

}

.App {
    min-height: calc(100vh - 14rem);
    position: relative;
    padding-bottom: 14rem;
}

/* REACTIVENESS */
@media screen and (max-width: 770px) {
    .App {
        min-height: calc(100vh - 19rem);
        padding-bottom: 19rem;
    }
}


/* firefox reactiveness */
@-moz-document url-prefix() { 
    .App { 
        padding-bottom: 27.5rem;
    }

    @media screen and (max-width: 770px) {
        .App {
            min-height: calc(100vh - 32rem);
            padding-bottom: 32rem;
        }
    }
}
