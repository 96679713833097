.media_container {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
}

.media_buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: var(--smallPadding);
}

.media_button {
    background-color: var(--darkPurple);
    border: none;
    color: var(--gold);
    padding: var(--smallPadding) var(--mediumPadding);
    min-width: 8rem;
    border-radius: 5px;
    cursor: pointer;
}

.media_display {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
}

.media_video {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
}

.media_imageDisplay {
    width: 100%;
    height: 100%;
    margin: var(--smallPadding) 0;
    border-radius: 5px;
    object-fit: contain;
}

.media_titles {
    color: var(--gold);
    margin: var(--mediumPadding) 0 var(--smallPadding) 0;
    text-align: center;
}

.media_flickr {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
}

/* REACTIVENESS */
@media screen and (max-width: 1000px) {
    .media_container {
        width: 60%;
    }
}

@media screen and (max-width: 550px) {
    .media_container {
        width: 70%;
    }
}

@media screen and (max-width: 450px) {
    .media_container {
        width: 70%;
    }

    .media_button {
        min-width: 5rem;
        font-size: 0.7rem;
        padding: var(--smallPadding) var(--smallPadding);
    }
}