@import url(https://fonts.googleapis.com/css2?family=Arvo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap);
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
:root {
    /* Colours */
    --mediumPurple: #8A3385;
    --darkPurple: #220044;
    --lightPurple: #A160A4;
    --gold: #EFAF2E;
    --cream: #FAE7CF;

    /* Padding */
    --smallPadding: 1rem;
    --mediumPadding: 2rem;
    --largePadding: 3rem;
    --hugePadding: 4rem;

    /* Font size */
    --normal: 1rem;
    --big: 2rem;
    --biggest: 2.5rem; 
}
/* Import fonts */ /* Arvo */ /* Raleway */

/* Aurabesh fonts */
@font-face {
    font-family: 'Aurebesh';
    src: url(/static/media/Aurebesh.aee485af.otf);
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Aurebesh';
    src: url("/static/media/Aurebesh Italic.0502d94d.otf");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Aurebesh';
    src: url("/static/media/Aurebesh Bold.bee81cf0.otf");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Aurebesh';
    src: url("/static/media/Aurebesh Bold Italic.7c76514a.otf");
    font-weight: bold;
    font-style: italic;
}

h1, h2, h3 {
    font-family: 'Arvo', serif;
}

h1:hover, h2:hover, h3:hover, p:hover, a:hover, li:hover, button:hover {
    font-family: 'Aurebesh', serif;
}



h1, p, a, li, button {
    font-size: var(--normal);

}

h2 {
    font-size: var(--biggest);
}

h3 {
    font-size: var(--big);
}


p, a, li, button {
    font-family: 'Raleway', sans-serif;
}
header {
    background-color: var(--darkPurple);
    color: var(--gold);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: var(--smallPadding) var(--mediumPadding);
}

.nav_links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.nav_link {
    padding: 0 var(--smallPadding);
    text-decoration: none;
    color: var(--gold);
}

.nav_link:hover, .header_Title :hover {
    cursor: pointer;
    color: var(--cream);
    font-size: 65%;

}

.header_Title {
    text-decoration: none;
    color: var(--gold);
}

.header_menuIcon {
    height: var(--big);
    display: none;
}


/* REACTIVENESS */
@media screen and (max-width: 670px) {
    .nav_links {
        display: none;
    }

    .header_menuIcon {
        display: flex;
    }
}
footer {
    position: absolute;
    bottom: 0;
    background-color: var(--darkPurple);
    height: 3rem;
    width: 100%;
}

.footer_copyright {
    color: var(--gold);

    text-align: center;
    padding: var(--smallPadding) var(--mediumPadding);

}
.hero {
    color: var(--gold);
    background-color: black;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
    box-sizing: border-box !important;

    height: 100vh;
}

.hero_logo {
    width: 25%;
    padding: var(--mediumPadding);
    position: relative;
}

.hero_subtitle {
    position: relative;
}

.hero_intro {
    text-align: center;
    line-height: 1.2;

    color: var(--mediumPurple);
    font-weight: 700;
    
    width: 45%;
    padding: var(--smallPadding);
    position: relative;
}

.hero_niwiMotto {
    display: inline;
    position: relative;
    color: var(--gold);
    font-style: italic;
    font-weight: normal;
}

.hero_continue {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    margin-bottom: 5rem;
    height: 100%;

}

.hero_learnMore {
    padding-top: var(--mediumPadding);
}

.hero_downArrow {
    padding-top: var(--smallPadding);
}


/* Video */
.videoContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.3;
    }

.videoContainer__video {
    /* optional */
    width: 100%;
    height: 100vh;
}


/* REACTIVENESS */
@media screen and (max-width: 670px) {
    .videoContainer__video {
        display: none;
    }

    .hero_intro {
        width: 90%;
    }

    .hero_subtitle {
        text-align: center;
        font-size: var(--big);
    }
}
/* Wraps Card Components */
.menu_container {
    margin: var(--smallPadding) auto;
    width: 50%;
    position: relative;
}

/* Card Component */
.menu_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    box-sizing: border-box;
}

.card_content {
    /* font */
    color: var(--cream);
    font-weight: 700;
    text-align: center;

    /* visibility and borders */
    opacity: 0;
    border-radius: 20px;

    /* background */
    background-color: rgba(0, 0, 0, 0.5);

    /* Animation */
    transition: all 400ms ease-out;

    /* div positioning */
    width: 100%;
    height: 30.33%;
    transform: translate(0%, 5%);
    position: absolute;
    box-sizing: border-box;
}

.card_content:hover, .card_Label {
    /* Make it visible when hovered */
    opacity: 1;
}

.card_Label {
    /* Animation for visibility */
    opacity: 1;
    transition-delay: 0s;
    transition-duration: 0.4s;
}

.menu_card .card_content .card_label {
    /* Center text */
    padding-top: 25%;
}

.card_image {
    position: relative;
    border-radius: 20px;
    width: 100%;
    margin: 3%;
}

/* REACTIVENESS */
@media screen and (max-width: 670px) {
    .menu_container {
        width: 70%;
    }

    /* make label visibile */
    .card_content {
        opacity: 1;
    }

    .menu_card .card_content .card_label {
        font-size: var(--normal);
    }
}
.squads_title {
    text-align: center;
    color: var(--gold);
    margin: var(--mediumPadding); 
}

.description {
    background-color: var(--darkPurple);
    width: 40%;
    min-width: 500px;
    border-radius: 10px;

    margin: var(--mediumPadding) auto;

    box-sizing: border-box;
}

.description_info,  .roster_title {
    color: var(--cream);
    text-align: center;
    padding: var(--smallPadding);

}

.description_body {
    color: var(--cream);
    text-align: center;
    padding: var(--smallPadding);
    padding-bottom: 5rem;
    line-height: 2;

    transition-property: font-size;
    transition-duration: 0.5s;
    /* transition-delay: 0s; */
}

.description_body:hover {
    font-size: 0.76rem;
}

.roster_players {
    color: var(--cream);
    padding: var(--mediumPadding);
    padding-top: var(--smallPadding);
    line-height: 2;
    margin-bottom: var(--hugePadding);

}

/* REACTIVENESS */
@media screen and (max-width: 720px) {
    .description {
        width: 90%;
        min-width: 300px;
    }
}
.squadronMenu {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 40%;

    margin: var(--hugePadding) auto;
}

.squadronMenu:hover {
    cursor: pointer;
} 

.icon_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    padding: 0 var(--mediumPadding);

}

.icon_label {
    color: var(--gold);
    text-align: center;
    padding: var(--smallPadding) 0;
    justify-content: center;

    transition-property: font-size;
    transition-duration: 0.3s;
}

.icon_label:hover {
    font-size: 0.6rem;
}

/* REACTIVENESS */
@media screen and (max-width: 670px) {
    .icon_item {
        padding: 0 0.5rem;
    }

    .icon_label {
        display: none;
    }
}


.categories_container {
    background-color: var(--darkPurple);
    margin: var(--largePadding) auto;
    padding: var(--mediumPadding);
    width: 40%;
    border-radius: 20px;
}

.categories_title {
    color: var(--gold);
    padding: 0 0 var(--smallPadding);
}

.categories_buttons {
    display: flex;
    justify-content: space-around;
}

.categories_button {
    border: none;
    border-radius: 10px;

    background-color: var(--lightPurple);
    color: var(--cream);

    padding: 0.5rem var(--smallPadding);
    width: 12rem;
}

.categories_button:hover {
    cursor: pointer;
}

/* REACTIVENESS */
@media screen and (max-width: 1000px) {
    .categories_title {
        font-size: var(--big);
    }

    .categories_button {
        width: 10rem;
    }

    .categories_container {
        width: 60%;
    }
}

@media screen and (max-width: 550px) {
    .categories_title {
        font-size: 1.3rem;
    }

    .categories_button {
        width: 6rem;
    }

    .categories_container {
        width: 60%;
    }
}
.media_container {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
}

.media_buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: var(--smallPadding);
}

.media_button {
    background-color: var(--darkPurple);
    border: none;
    color: var(--gold);
    padding: var(--smallPadding) var(--mediumPadding);
    min-width: 8rem;
    border-radius: 5px;
    cursor: pointer;
}

.media_display {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
}

.media_video {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
}

.media_imageDisplay {
    width: 100%;
    height: 100%;
    margin: var(--smallPadding) 0;
    border-radius: 5px;
    object-fit: contain;
}

.media_titles {
    color: var(--gold);
    margin: var(--mediumPadding) 0 var(--smallPadding) 0;
    text-align: center;
}

.media_flickr {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
}

/* REACTIVENESS */
@media screen and (max-width: 1000px) {
    .media_container {
        width: 60%;
    }
}

@media screen and (max-width: 550px) {
    .media_container {
        width: 70%;
    }
}

@media screen and (max-width: 450px) {
    .media_container {
        width: 70%;
    }

    .media_button {
        min-width: 5rem;
        font-size: 0.7rem;
        padding: var(--smallPadding) var(--smallPadding);
    }
}
.join_container {
    color: var(--cream);
    text-align: center;
    line-height: 1.5;

    width: 50%;
    border-radius: 10px;

    margin: var(--mediumPadding) auto;
}

.join_title {
    color: var(--gold)
}

.join_section {
    text-align: center;
    background-color: var(--darkPurple);
    
    padding: var(--mediumPadding);
    margin: var(--mediumPadding);

    border-radius: 20px;
}

.join_list {
    counter-reset: list-number;
    list-style-type: decimal;
    text-align: left;
}

.join_list li, .join_Instructionlist li {
    margin-top: var(--mediumPadding);
}

.join_discordLogo {
    display: flex;
    justify-content: center;
    padding: var(--smallPadding) 0 0 0;
}

/* REACTIVENESS */
@media screen and (max-width: 720px) {
    .join_container {
        width: 90%;
        min-width: 300px;
    }
}
.menu_links {
    height: 90vh;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    padding: var(--mediumPadding);

    background-color: var(--darkPurple);
}

.menu_link {
    text-decoration: none;
    color: var(--gold);
}



/* Base */

/* common */

/* home */

/* Squadrons */

/* Videos */

/* Join Us */

/* Menu burger */


body {
    background-image: url(/static/media/backgroundImage.550a5b0e.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;

    position:absolute;
    width: 100%;

}

.App {
    min-height: calc(100vh - 14rem);
    position: relative;
    padding-bottom: 14rem;
}

/* REACTIVENESS */
@media screen and (max-width: 770px) {
    .App {
        min-height: calc(100vh - 19rem);
        padding-bottom: 19rem;
    }
}


/* firefox reactiveness */
@-moz-document url-prefix() { 
    .App { 
        padding-bottom: 27.5rem;
    }

    @media screen and (max-width: 770px) {
        .App {
            min-height: calc(100vh - 32rem);
            padding-bottom: 32rem;
        }
    }
}

