/* Wraps Card Components */
.menu_container {
    margin: var(--smallPadding) auto;
    width: 50%;
    position: relative;
}

/* Card Component */
.menu_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    box-sizing: border-box;
}

.card_content {
    /* font */
    color: var(--cream);
    font-weight: 700;
    text-align: center;

    /* visibility and borders */
    opacity: 0;
    border-radius: 20px;

    /* background */
    background-color: rgba(0, 0, 0, 0.5);

    /* Animation */
    -webkit-transition: all 400ms ease-out;
    -moz-transition: all 400ms ease-out;
    -o-transition: all 400ms ease-out;
    -ms-transition: all 400ms ease-out;
    transition: all 400ms ease-out;

    /* div positioning */
    width: 100%;
    height: 30.33%;
    transform: translate(0%, 5%);
    position: absolute;
    box-sizing: border-box;
}

.card_content:hover, .card_Label {
    /* Make it visible when hovered */
    opacity: 1;
}

.card_Label {
    /* Animation for visibility */
    opacity: 1;
    transition-delay: 0s;
    transition-duration: 0.4s;
}

.menu_card .card_content .card_label {
    /* Center text */
    padding-top: 25%;
}

.card_image {
    position: relative;
    border-radius: 20px;
    width: 100%;
    margin: 3%;
}

/* REACTIVENESS */
@media screen and (max-width: 670px) {
    .menu_container {
        width: 70%;
    }

    /* make label visibile */
    .card_content {
        opacity: 1;
    }

    .menu_card .card_content .card_label {
        font-size: var(--normal);
    }
}