.squads_title {
    text-align: center;
    color: var(--gold);
    margin: var(--mediumPadding); 
}

.description {
    background-color: var(--darkPurple);
    width: 40%;
    min-width: 500px;
    border-radius: 10px;

    margin: var(--mediumPadding) auto;

    box-sizing: border-box;
}

.description_info,  .roster_title {
    color: var(--cream);
    text-align: center;
    padding: var(--smallPadding);

}

.description_body {
    color: var(--cream);
    text-align: center;
    padding: var(--smallPadding);
    padding-bottom: 5rem;
    line-height: 2;

    transition-property: font-size;
    transition-duration: 0.5s;
    /* transition-delay: 0s; */
}

.description_body:hover {
    font-size: 0.76rem;
}

.roster_players {
    color: var(--cream);
    padding: var(--mediumPadding);
    padding-top: var(--smallPadding);
    line-height: 2;
    margin-bottom: var(--hugePadding);

}

/* REACTIVENESS */
@media screen and (max-width: 720px) {
    .description {
        width: 90%;
        min-width: 300px;
    }
}